<template>
  <div class="fixed grid-template-top-bar left-0 right-0 top-0 grid px-6 items-center gap-4 h-16 bg-veturi-dark text-veturi-white text-xl">
    <router-link
      :to="backTo"
      v-if="backTo"
    >
      <icon
        name="arrow-left"
        size="30"
        class="justify-self-start"
      />
    </router-link>
    <veturi-logo v-else class=" col-span-2 h-12 w-20 justify-self-start" />
    <div
      :class="{
        'col-span-9': !backTo,
        'col-span-10': backTo
      }"
      class="justify-self-center font-bold text-3xl"
    >
      {{ title }}
    </div>
    <!-- <ul class="col-span-5 justify-self-end flex font-bold"> -->
      <!-- <li class="flex ml-8"><router-link to="/home">Home</router-link></li> -->
      <!-- <li class="flex ml-8"><router-link to="/data">Custos</router-link></li> -->
    <!-- </ul> -->
    <button id="logout-button" @click="logout" class="justify-self-end focus:outline-none">Sair</button>
  </div>
</template>

<script>
import { mapState, useStore } from 'vuex'
import { auth } from '@/services'
import VeturiLogo from '@/components/VeturiLogo'
import Icon from '@/components/Icon'

export default {
  components: {
    VeturiLogo,
    Icon
  },

  props: {
    title: { type: String, default: 'Veturi' },
    backTo: { type: String, default: null }
  },

  computed: mapState(['admin']),

  setup (props) {
    const store = useStore()
    const back = store.state.backTo

    async function logout () {
      await auth.logout()
    }

    return {
      logout,
      back
    }
  }
}
</script>

<style scoped>
.grid-template-top-bar {
  grid-template-columns: repeat(12, 1fr);
}
.router-link-exact-active {
  @apply text-veturi-accent;
}
</style>
